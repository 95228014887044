import { Box, Divider, Stack, Typography } from "@mui/material";
import {
    Price,
    Product,
    Subscription,
} from "@stripe/firestore-stripe-payments";
import { ReactComponent as IconCheckmarkPink } from "../../assets/icon-checkmark-pink.svg";
import StyledButton from "../common/StyledButton";

type SubscriptionDescriptionProps = {
  active: boolean;
  onClickSelectPlan: (price: Price | undefined) => void;
  billingInterval: string;
  product: Product;
  activeSubscription: Subscription | null;
};

export function SubscriptionDescription({
  onClickSelectPlan,
  billingInterval,
  product,
  activeSubscription,
}: SubscriptionDescriptionProps) {
  let features: string[] = [];
  if (typeof product.metadata["featureList"] === "string") {
    // A string property set in the Stripe product dashboard
    features = product.metadata["featureList"].split(",");
  }

  let price: Price | undefined;
  let priceDisplay = null;
  for (let i = 0; i < product.prices.length; i++) {
    const priceObject = product.prices[i];
    if (priceObject.recurring.interval === billingInterval) {
      price = priceObject;
      priceDisplay = (
        <Box>
          <Typography
            fontSize="36px"
            fontWeight="fontWeightBold"
            color="white"
            display="inline-block"
          >
            ${(priceObject.unit_amount || 0) / 100}
          </Typography>
          <Typography fontSize="14px" color="white" display="inline-block">
            /{billingInterval}
          </Typography>
        </Box>
      );
      break;
    }
  }

  const handleClickSelectPlan = () => {
    onClickSelectPlan(price);
  };

  const isCurrentPlan = activeSubscription
    ? activeSubscription.price === price!.id
    : product.name === "Free Plan";

  return (
    <Box
      sx={(theme) => ({
        p: 4,
        borderRadius: 4,
        border: `1px solid ${theme.palette.secondary.main}`,
        backgroundColor: "#191835",
        width: "400px",
        boxSizing: "border-box",
      })}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontSize="20px" fontWeight="fontWeightBold" color="white">
          {product.name}
        </Typography>
        {priceDisplay}
      </Box>
      <StyledButton
        color={"emphasized"}
        sx={{ color: "white", my: 3 }}
        fullWidth
        disabled={isCurrentPlan}
        onClick={handleClickSelectPlan}
      >
        {isCurrentPlan ? "Current Plan" : "Select Plan"}
      </StyledButton>
      <Divider
        sx={(theme) => ({ backgroundColor: theme.palette.secondary.dark })}
      />
      <Stack spacing={2} sx={{ mt: 2 }}>
        {features.map((feature, index) => (
          <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
            <IconCheckmarkPink />
            <Typography color="secondary.light" fontSize="14px" sx={{ ml: 2 }}>
              {feature}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
