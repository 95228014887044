import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Popover,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { ReactComponent as IconLeftArrow } from "../../../../assets/icon-left-arrow.svg";
import { ReactComponent as IconMagnifyingGlass } from "../../../../assets/icon-magnifying-glass.svg";
import { ReactComponent as IconX } from "../../../../assets/icon-x.svg";
import ModelData, {
  PotionModel,
  PotionModelSpell,
} from "../../../../assets/model-data";
import RotatingArrowIcon from "../../../common/RotatingArrowIcon";
import StyledTextField from "../../../common/StyledTextField";
import StyledCard from "./StyledCard";

const getPotionModelImage = (potionModel: PotionModel) => {
  return getPotionSpellImage(potionModel, potionModel.prompts[0].icon);
};

const getPotionSpellImage = (potionModel: PotionModel, icon: string) => {
  return potionModel.assets_location + "/examples/" + icon;
};

type PotionModelChooserProps = {
  onClickModel: (potionModel: PotionModel) => void;
  onClickSpell: (prompt: string) => void;
  disabled?: boolean;
};

const PotionModelChooser = ({
  onClickModel,
  onClickSpell,
  disabled,
}: PotionModelChooserProps) => {
  const potionChooserRef = useRef<HTMLDivElement | null>(null);
  const [searchStr, setSearchStr] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [potionModel, setPotionModel] = React.useState<PotionModel | null>(
    null
  );

  useEffect(() => {
    if (potionChooserRef.current) {
      setAnchorEl(potionChooserRef.current);
    }
  }, [potionChooserRef]);

  const filteredModels = React.useMemo(() => {
    return ModelData.models.filter((model) =>
      model.name.toLowerCase().includes(searchStr)
    );
  }, [searchStr]);

  const handleChangeSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchStr(e.target.value);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClickPotionModel = (potionModel: PotionModel) => {
    setPotionModel(potionModel);
    onClickModel(potionModel);
  };

  const handleClickPotionSpell = (potionModelSpell: PotionModelSpell) => {
    onClickSpell(potionModelSpell.caption);
    handleClosePopover();
  };

  const handleClearPotionModel = () => {
    setPotionModel(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box
        ref={potionChooserRef}
        sx={{
          display: "flex",
          alignItems: "center",
          borderRadius: 2,
          color: disabled ? "secondary.dark" : "secondary.main",
          backgroundColor: disabled ? "primary.dark" : "primary.main",
          p: 1.5,
          cursor: disabled ? "auto" : "pointer",
          height: "58px",
          width: "330px",
        }}
        onClick={handleOpenPopover}
      >
        {potionModel && (
          <Box
            sx={{
              height: "34px",
              width: "34px",
              backgroundImage: `url(${getPotionModelImage(potionModel)})`,
              backgroundSize: "contain",
              borderRadius: 2,
            }}
          />
        )}
        <Typography
          variant="body1"
          color={disabled ? "secondary.dark" : "secondary.light"}
          fontWeight={potionModel ? "fontWeightMedium" : "fontWeightLight"}
          fontSize="14px"
          sx={{ ml: 1 }}
        >
          {potionModel ? potionModel.display_name : "Choose a model"}
        </Typography>
        <Box sx={{ display: "flex", flexGrow: 1 }} />
        <RotatingArrowIcon degrees={open ? 90 : 0} />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={(theme) => ({
          ">.MuiPaper-root": {
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: theme.palette.secondary.main,
              },
            },
            p: 2,
            borderRadius: 2,
            width: 500,
            ml: 2,
            mt: -8,
            maxHeight: 660,
          },
        })}
      >
        {potionModel ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                onClick={handleClearPotionModel}
                sx={(theme) => ({
                  border: `1px solid ${theme.palette.secondary.main}`,
                  borderRadius: 2,
                })}
                size="small"
              >
                <IconLeftArrow />
              </IconButton>
              <Typography
                color="secondary"
                fontSize={14}
                sx={{ ml: 2, cursor: "pointer" }}
                onClick={handleClearPotionModel}
              >
                Back to model selector
              </Typography>
            </Box>
            <IconButton onClick={handleClosePopover} size="small">
              <IconX />
            </IconButton>
          </Box>
        ) : (
          <StyledTextField
            value={searchStr}
            onChange={handleChangeSearchString}
            fullWidth
            variant="outlined"
            placeholder="Type to search a style model name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconMagnifyingGlass />
                </InputAdornment>
              ),
            }}
          />
        )}
        <Typography
          color="white"
          sx={{ mt: 2, mb: 1 }}
          fontWeight="fontWeightMedium"
          fontSize={16}
        >
          Potion Models
        </Typography>

        {!potionModel && (
          <>
            <Typography color="secondary" sx={{ mt: 2 }} fontSize={14}>
              {filteredModels.length === 0
                ? "No potion models found"
                : "Pick a potion model"}
            </Typography>
            <Grid container spacing={1} sx={{ mt: 0.5 }}>
              {filteredModels.map((model) => (
                <Grid key={model.name} item xs={4}>
                  <StyledCard
                    image={
                      model.assets_location +
                      "/examples/" +
                      model.prompts[0].icon
                    }
                    label={model.display_name}
                    onClick={() => handleClickPotionModel(model)}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {potionModel && (
          <>
            <StyledCard
              image={
                potionModel.assets_location +
                "/examples/" +
                potionModel.prompts[0].icon
              }
              label={potionModel.display_name}
              selected
            />
            <Typography
              color="white"
              sx={{ mt: 2, mb: 1 }}
              fontWeight="fontWeightMedium"
              fontSize={16}
            >
              Spell Examples
            </Typography>
            <Typography color="secondary" fontSize={14}>
              Click to copy prompt (optional)
            </Typography>
            <Grid container spacing={1} sx={{ mt: 0.5 }}>
              {potionModel.prompts.map((prompt) => (
                <Grid key={prompt.icon} item xs={4}>
                  <StyledCard
                    image={getPotionSpellImage(potionModel, prompt.icon)}
                    label={prompt.caption}
                    onClick={() => handleClickPotionSpell(prompt)}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Popover>
    </>
  );
};

export default PotionModelChooser;
