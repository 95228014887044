import { Typography } from "@mui/material";
import React from "react";
import StyledDialog from "../components/common/StyledDialog";

type Error = {
  title: string;
  message: string | React.ReactNode;
};

type ErrorContextType = {
  openErrorDialog: (error: Error) => void;
};

const ErrorContext = React.createContext<ErrorContextType>({
  openErrorDialog: (error: Error) => {},
});

const ErrorContextProvider = ({ children }: React.PropsWithChildren) => {
  const [error, setError] = React.useState<Error | null>(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const openErrorDialog = (error: Error) => {
    setError(error);
    setDialogOpen(true);
  };

  const closeErrorDialog = () => {
    setDialogOpen(false);
  };

  return (
    <ErrorContext.Provider value={{ openErrorDialog }}>
      {children}
      <StyledDialog open={dialogOpen} onClose={closeErrorDialog}>
        <Typography
          color="white"
          fontWeight="fontWeightBold"
          fontSize="20px"
          alignSelf="center"
          px={4}
          pb={2}
          component="div"
        >
          {error?.title}
        </Typography>
        {typeof error?.message === "string" ? (
          <Typography color="white" fontSize="14px" alignSelf="center">
            {error?.message}
          </Typography>
        ) : (
          error?.message
        )}
      </StyledDialog>
    </ErrorContext.Provider>
  );
};

const DEFAULT_ERROR: Error = {
  title: "Oops, transmutation failed!",
  message:
    "Don't get discouraged. Double check your formula. Better luck next brew!",
};

export { DEFAULT_ERROR, ErrorContext, ErrorContextProvider };
