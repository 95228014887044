import { useMutation } from "@tanstack/react-query";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import useGamingAssetsApi, {
  API_IMAGE_TYPE,
  RemoveBackgroundOptions,
} from "../../../apis/useGamingAssetsApi";
import { DEFAULT_ERROR, ErrorContext } from "../../../contexts/ErrorContext";
import { ImageThumbnail } from "..";
import Expander from "../../common/Expander";
import StyledButton from "../../common/StyledButton";

type RemoveBgExpanderProps = {
  selectedImage: ImageThumbnail | null;
  expanded: boolean;
  onExpand: (isExpanded: boolean) => void;
  onProcessingStart: () => void;
  onProcessingEnd: (result?: ImageThumbnail[]) => void;
  disabled?: boolean;
};
const RemoveBgExpander = ({
  selectedImage,
  expanded,
  onExpand,
  onProcessingStart,
  onProcessingEnd,
  disabled: propsDisabled,
}: RemoveBgExpanderProps) => {
  const { openErrorDialog } = React.useContext(ErrorContext);
  const api = useGamingAssetsApi();

  const { mutate, isLoading } = useMutation({
    mutationFn: (vars: RemoveBackgroundOptions) =>
      api.removeBackground(vars, onProcessingStart),
    onSuccess: (data, vars) => {
      const src = URL.createObjectURL(data.data);
      const img = new Image();
      img.src = src;
      img.onload = async () => {
        onProcessingEnd([
          {
            name: vars.imageThumbnail.name,
            ext: API_IMAGE_TYPE,
            blob: data.data,
            img,
            uuid: uuidv4(),
          },
        ]);
      };
    },
    onError: () => {
      openErrorDialog(DEFAULT_ERROR);
      onProcessingEnd();
    },
  });

  const handleClickRemoveBackground = async () => {
    if (selectedImage) {
      mutate({ imageThumbnail: selectedImage });
    }
  };

  const disabled = propsDisabled || !selectedImage || isLoading;

  return (
    <Expander text="Remove Background" expanded={expanded} onChange={onExpand}>
      <StyledButton
        onClick={handleClickRemoveBackground}
        disabled={disabled}
        fullWidth
        sx={{ mt: 2 }}
      >
        Remove Background
      </StyledButton>
    </Expander>
  );
};

export default RemoveBgExpander;
