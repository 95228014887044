import { Box } from "@mui/material";
import { ReactComponent as IconRightArrow } from "../../assets/icon-right-arrow.svg";

type RotatingArrowIconProps = {
  degrees: number;
};

const RotatingArrowIcon = ({ degrees }: RotatingArrowIconProps) => {
  return (
    <Box
      sx={{
        color: "inherit",
        height: "24px",
        width: "24px",
        transform: `rotate(${degrees}deg)`,
        transition: "transform 200ms",
      }}
    >
      <IconRightArrow />
    </Box>
  );
};

export default RotatingArrowIcon;
