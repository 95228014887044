import { Box, Typography } from "@mui/material";
import Slider from "../../common/StyledSlider";

type ImageSizeSliderProps = {
  value: number;
  onChange: (size: number) => void;
  disabled?: boolean;
  label: string;
  min: number;
  max: number;
  stepSize: number;
};

const SquareSizeSlider = ({
  value,
  onChange,
  disabled,
  label,
  min,
  max,
  stepSize,
}: ImageSizeSliderProps) => {
  const handleChange = (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => {
    if (typeof value !== "number") {
      // Dunno why this might happen but the API says it could return an array
      console.error("Slider change event was not a number!");
      return;
    }
    onChange(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Typography
          color="secondary.light"
          fontSize="14px"
          fontWeight="fontWeightMedium"
        >
          {label}
        </Typography>
        <Typography color="white" fontSize="14px" ml={2}>
          {value}x{value}
        </Typography>
      </Box>
      <Slider
        disabled={disabled}
        value={value}
        onChange={handleChange}
        step={stepSize}
        min={min}
        max={max}
        sx={{
          alignSelf: "center",
          width: "calc(100% - 24px)",
        }}
      />
      <Box display="flex" justifyContent="space-between">
        <Typography color="secondary.light" fontSize="14px">
          {min}x{min}
        </Typography>
        <Typography color="secondary.light" fontSize="14px">
          {max}x{max}
        </Typography>
      </Box>
    </Box>
  );
};

export default SquareSizeSlider;
