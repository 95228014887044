import { Box } from "@mui/material";
import StyledSwitch from "../../common/StyledSwitch";
import WidgetLabel from "./WidgetLabel";

type LabeledSwitchProps = {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
  labelWidth?: number;
  disabled?: boolean;
};

const LabeledSwitch = ({
  label,
  value,
  onChange,
  labelWidth,
  disabled,
}: LabeledSwitchProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <WidgetLabel label={label} width={labelWidth} />
      <StyledSwitch
        disabled={disabled}
        color="secondary"
        checked={value}
        onChange={handleChange}
      />
    </Box>
  );
};

export default LabeledSwitch;
