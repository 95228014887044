import { Box, Collapse, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as IconICircle } from "../../assets/icon-i-circle.svg";
import RotatingArrowIcon from "./RotatingArrowIcon";
import StyledTooltip from "./StyledTooltip";

type ExpanderProps = {
  text: string;
  expanded: boolean;
  onChange: (isExpanded: boolean) => void;
  disabled?: boolean;
  hint?: string;
} & React.PropsWithChildren;

const Expander = ({
  text,
  expanded,
  onChange,
  children,
  disabled,
  hint,
}: ExpanderProps) => {
  const handleClick = () => {
    if (!disabled) {
      onChange(!expanded);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          borderRadius: 2,
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "48px",
          px: 2,
          py: 1,
          ":hover": {
            cursor: disabled ? "auto" : "pointer",
          },
        }}
        color={disabled ? "secondary.dark" : "secondary.main"}
        bgcolor={disabled ? "primary.dark" : "primary.main"}
        onClick={handleClick}
      >
        {hint && (
          <StyledTooltip title={hint}>
            <Box
              sx={(theme) => ({
                mr: 2,
                display: "flex",
                alignItems: "center",
                color: theme.palette.secondary.main,
                ":hover": { color: "white" },
              })}
            >
              <IconICircle />
            </Box>
          </StyledTooltip>
        )}
        <Typography color="inherit" fontSize="14px" fontWeight={600}>
          {text}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <RotatingArrowIcon degrees={expanded ? 270 : 90} />
      </Box>
      <Collapse in={expanded}>
        <Box mt={2}>{children}</Box>
      </Collapse>
    </Box>
  );
};

export default Expander;
