import {
  Box,
  CircularProgress,
  Fade,
  Input,
  Theme,
  Typography,
} from "@mui/material";
import React from "react";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as IconCamera } from "../../../assets/icon-camera.svg";
import { ErrorContext } from "../../../contexts/ErrorContext";
import { fileExtension, fileName } from "../../../utils";
import { ImageThumbnail } from "..";

const getDashedBorderBackgroundImage = (theme: Theme) => {
  // Can't control dash length with default CSS so using this hack
  // https://kovart.github.io/dashed-border-generator/
  const pink = theme.palette.pink1.main.slice(1, 7);
  return `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23${pink}' stroke-width='2' stroke-dasharray='14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`;
};

type DropzoneProps = {
  isLoading: boolean;
  hidden: boolean;
  onUploadStart: () => void;
  onUploadEnd: (images: ImageThumbnail[]) => void;
};

const Dropzone = ({
  isLoading,
  hidden,
  onUploadStart,
  onUploadEnd,
}: DropzoneProps) => {
  const { openErrorDialog } = React.useContext(ErrorContext);
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      maxFiles: 1,
      accept: {
        "image/jpeg": [".jpg", ".jpeg"],
        "image/png": [".png"],
      },
      disabled: isLoading,
    });

  React.useEffect(() => {
    if (fileRejections.length > 0) {
      const errors = fileRejections.reduce((acc, curr) => {
        for (let err of curr.errors) {
          if (!acc.includes(err.message)) {
            acc.push(err.message);
          }
        }
        return acc;
      }, [] as string[]);
      openErrorDialog({
        title: "Oops, file upload failed!",
        message: errors[0],
      });
    }
  }, [fileRejections, openErrorDialog]);

  React.useEffect(() => {
    if (acceptedFiles.length > 0) {
      onUploadStart();
      let processedFileCount = 0;
      let imageThumbnails: ImageThumbnail[] = [];
      acceptedFiles.forEach((file, index) => {
        var reader = new FileReader();
        reader.onloadend = function () {
          if (typeof reader.result !== "string") {
            console.error("something went wrong");
            return;
          }
          const img = new Image();
          img.src = reader.result;
          imageThumbnails.push({
            name: fileName(file.name),
            ext: fileExtension(file.name),
            blob: file,
            img,
            uuid: uuidv4(),
          });
          processedFileCount++;
          if (processedFileCount === acceptedFiles.length) {
            onUploadEnd(imageThumbnails);
          }
        };
        reader.readAsDataURL(file);
      });
    }
  }, [acceptedFiles, onUploadStart, onUploadEnd]);

  // Pulling off the props that aren't compatible with MUI
  const { color, size, ...inputProps } = getInputProps();

  return (
    <Box
      {...getRootProps()}
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "660px",
        width: "660px",
        position: hidden ? "absolute" : "relative",
        zIndex: hidden ? -1 : 0,
        opacity: hidden ? 0 : 1,
        backgroundImage: getDashedBorderBackgroundImage(theme),
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        cursor: isLoading ? "auto" : "pointer",
        flexGrow: 1,
      })}
    >
      {!isLoading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Input {...inputProps} />
          <IconCamera />
          <Typography
            variant="button"
            color="pink1.main"
            sx={{ mt: 4, width: "369px", textAlign: "center" }}
          >
            See your result or drag and drop your image to modify
          </Typography>
          <Typography variant="button" color="secondary.light" sx={{ mt: 2 }}>
            Supports jpg png
          </Typography>
        </Box>
      )}
      <Fade in={isLoading} unmountOnExit>
        <CircularProgress color="secondary" size="10rem" />
      </Fade>
    </Box>
  );
};

export default Dropzone;
