import {
  AppBar,
  Box,
  Button,
  IconButton,
  MenuItem,
  Toolbar,
} from "@mui/material";
import React from "react";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ReactComponent as IconDiscord } from "./assets/icon-discord.svg";
import { ReactComponent as IconLogo } from "./assets/icon-logo.svg";
import { ReactComponent as IconProfile } from "./assets/icon-profile.svg";
import AssetDesigner from "./components/AssetDesigner";
import Subscription from "./components/Subscription";
import StyledDivider from "./components/common/StyledDivider";
import StyledMenu from "./components/common/StyledMenu";
import { DISCORD_LINK } from "./constants";
import { AuthContext } from "./contexts/AuthContext";

const App = () => {
  const { user, signOut, openSessionDialog } = React.useContext(AuthContext);
  const location = useLocation();
  const [menuAnchorEl, setMenuAnchorEl] =
    React.useState<null | HTMLButtonElement>(null);

  const menuOpen = Boolean(menuAnchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const handleClickLogin = () => {
    openSessionDialog();
    handleClose();
  };

  const handleClickLogout = () => {
    signOut();
    handleClose();
  };

  const handleClickTOS = () => {
    window.open(
      "https://rosebudstartup.notion.site/Terms-of-Service-cd08d6a076a041868696a8097931fdc4",
      "_blank"
    );
  };

  const handleClickHelpCenter = () => {
    window.open("https://www.rosebud.ai/help-center", "_blank");
  };

  const loggedInMenuItems = [
    <Link
      to={{ pathname: "/subscription", search: location.search }}
      key="subscription"
      style={{
        textDecoration: "none",
        color: "white",
      }}
    >
      <MenuItem disableRipple>Subscription</MenuItem>
    </Link>,
    <StyledDivider key="subscription-divider" />,
    <MenuItem key="tos" onClick={handleClickTOS} disableRipple>
      Terms of service
    </MenuItem>,
    <StyledDivider key="settings-divider" />,
    <MenuItem key="help-center" onClick={handleClickHelpCenter} disableRipple>
      Help Center
    </MenuItem>,
    <StyledDivider key="help-center-divider" />,
  ];

  return (
    <>
      <AppBar position="static">
        <Toolbar sx={{ px: 5, py: 2 }} disableGutters>
          <IconButton sx={{ padding: 0 }} component={Link} to="/">
            <IconLogo />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton sx={{ padding: 0 }} href={DISCORD_LINK} target="_blank">
            <IconDiscord />
          </IconButton>
          {user && (
            <Button
              variant="outlined"
              color="emphasized"
              sx={{ ml: 3, borderRadius: 2 }}
              component={Link}
              to={{ pathname: "/subscription", search: location.search }}
            >
              Subscription
            </Button>
          )}
          <Button
            sx={{ mx: 3 }}
            color="secondary"
            href="https://www.rosebud.ai/ai-game-assets"
            target="_blank"
          >
            About
          </Button>
          <IconButton
            onClick={handleOpenMenu}
            size="small"
            sx={(theme) => ({
              button: { padding: 0 },
              color: user
                ? theme.palette.pink1.main
                : theme.palette.secondary.main,
            })}
          >
            <IconProfile />
          </IconButton>
          <StyledMenu
            anchorEl={menuAnchorEl}
            open={menuOpen}
            onClose={handleClose}
          >
            {user && loggedInMenuItems}
            <MenuItem
              onClick={user ? handleClickLogout : handleClickLogin}
              disableRipple
            >
              {user ? "Sign out" : "Sign in"}
            </MenuItem>
          </StyledMenu>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          position: "absolute",
          top: "72px",
          bottom: 0,
          left: 0,
          right: 0,
          overflow: "auto",
        }}
      >
        <Routes>
          <Route path="/" element={<AssetDesigner />} />
          <Route
            path="/subscription"
            element={user ? <Subscription /> : <Navigate to="/" />}
          />
        </Routes>
      </Box>
    </>
  );
};

export default App;
