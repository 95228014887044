import path from "path-browserify";

const fileName = (fp: string) => {
  return path.basename(fp, path.extname(fp));
};

const fileExtension = (fp: string) => {
  return path.extname(fp);
};

export { fileName, fileExtension };
