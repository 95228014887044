import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { ImageThumbnail } from "..";
import useGamingAssetsApi, {
  API_IMAGE_TYPE,
  RevibeImageOptions,
} from "../../../apis/useGamingAssetsApi";
import { AuthContext } from "../../../contexts/AuthContext";
import { DEFAULT_ERROR, ErrorContext } from "../../../contexts/ErrorContext";
import Expander from "../../common/Expander";
import StyledButton from "../../common/StyledButton";
import StyledTextField from "../../common/StyledTextField";
import RevibeOptionComponent from "./RevibeOption";

type RevibeOption = {
  name: string;
  visualize: Blob;
  mask: Blob;
  src: string;
};

type RevibeExpanderProps = {
  selectedImage: ImageThumbnail | null;
  expanded: boolean;
  onExpand: (isExpanded: boolean) => void;
  onProcessingStart: () => void;
  onProcessingEnd: (result?: ImageThumbnail[]) => void;
  disabled?: boolean;
};

const RevibeExpander = ({
  selectedImage,
  expanded,
  onExpand,
  onProcessingStart,
  onProcessingEnd,
  disabled: propsDisabled,
}: RevibeExpanderProps) => {
  const { openErrorDialog } = React.useContext(ErrorContext);
  const api = useGamingAssetsApi();
  const { user } = React.useContext(AuthContext);
  const [selectedRevibeOption, setSelectedRevibeOption] =
    React.useState<RevibeOption | null>(null);
  const [prompt, setPrompt] = React.useState("");

  const query = useQuery({
    queryKey: ["revibeMasks", selectedImage],
    queryFn: () =>
      api.getRevibeMasks(selectedImage!.blob, () =>
        setSelectedRevibeOption(null)
      ),
    enabled: !!selectedImage && !!user,
    staleTime: Infinity,
    onError: () => {
      openErrorDialog({
        title: "Oops, error generating masks!",
        message:
          "It looks like our servers are down as we deploy updates. Please try again later!",
      });
      onProcessingEnd();
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (vars: RevibeImageOptions) =>
      api.revibeImage(vars, onProcessingStart),
    onSuccess: (data, vars) => {
      const src = URL.createObjectURL(data.data);
      const img = new Image();
      img.src = src;
      img.onload = async () => {
        onProcessingEnd([
          {
            name: vars.imageThumbnail.name,
            ext: API_IMAGE_TYPE,
            blob: data.data,
            img,
            uuid: uuidv4(),
          },
        ]);
      };
    },
    onError: () => {
      openErrorDialog(DEFAULT_ERROR);
      onProcessingEnd();
    },
  });

  const handleChangePrompt = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(e.currentTarget.value);
  };

  const handleChangeRevibeOption = (revibeOption: RevibeOption) => {
    if (revibeOption.name === selectedRevibeOption?.name) {
      setSelectedRevibeOption(null);
    } else {
      setSelectedRevibeOption(revibeOption);
    }
  };

  const handleClickRevibe = async () => {
    if (selectedImage && selectedRevibeOption) {
      mutate({
        imageThumbnail: selectedImage,
        maskBlob: selectedRevibeOption.mask,
        prompt,
      });
    }
  };

  const buttonDisabled =
    !(selectedRevibeOption && prompt && selectedImage) ||
    propsDisabled ||
    isLoading;
  const optionsDisabled = propsDisabled || isLoading;

  let RevibeArea;
  if (query.data) {
    RevibeArea = query.data.map((option) => (
      <RevibeOptionComponent
        key={option.name}
        image={"data:image/png;base64, " + option.src}
        label={option.name}
        selected={selectedRevibeOption?.name === option.name}
        onClick={() => handleChangeRevibeOption(option)}
        disabled={optionsDisabled}
      />
    ));
  } else if (!query.isFetching) {
    let text;
    if (!selectedImage && !user) {
      text = "Sign-in and select an image to generate masks";
    } else if (!user) {
      text = "Sign-in to generate masks";
    } else {
      text = "Select an image to generate masks";
    }

    RevibeArea = (
      <Typography
        color="secondary.dark"
        fontSize="14px"
        sx={{ width: "100%", textAlign: "center" }}
      >
        {text}
      </Typography>
    );
  }

  return (
    <Expander
      text="Re-Vibe"
      hint="Select auto detected area, type a prompt, and Re-Vibe will  generate a new image with the selected area modified as described. "
      expanded={expanded}
      onChange={onExpand}
    >
      <Stack spacing={2}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {query.isFetching && (
            <Box sx={{ width: "100%", mt: 2 }}>
              <LinearProgress color="pink1" />
              <Typography
                color="secondary.dark"
                fontSize="14px"
                sx={{ width: "100%", textAlign: "center", my: 1 }}
              >
                Generating masks...
              </Typography>
            </Box>
          )}
          {RevibeArea}
        </Box>
        <StyledTextField
          disabled={optionsDisabled}
          value={prompt}
          onChange={handleChangePrompt}
          multiline
          placeholder={`Describe your changes in detail, specify style for better results. E.g. "yellow bunny in a cartoony style".`}
          sx={{ textarea: { minHeight: 80 } }}
        />
        <StyledButton onClick={handleClickRevibe} disabled={buttonDisabled}>
          {!selectedImage ? "Select an image to re-vibe" : "Re-Vibe"}
        </StyledButton>
      </Stack>
    </Expander>
  );
};

export default RevibeExpander;
