import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "textarea, input": {
    "::placeholder": {
      color: theme.palette.secondary.light,
    },
    color: "white",
    fontSize: "14px",
  },
  ".MuiOutlinedInput-root": {
    padding: "8px 12px",
    backgroundColor: theme.palette.emphasizedBg.dark,
    " fieldset": {
      borderColor: theme.palette.secondary.main,
      borderRadius: 8,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.secondary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.emphasized.main,
    },
  },
  ".Mui-disabled": {
    ".MuiInputBase-input": {
      color: theme.palette.secondary.dark,
      WebkitTextFillColor: "currentColor",
    },
    "&.MuiOutlinedInput-root": {
      " fieldset": {
        borderColor: theme.palette.secondary.dark,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.secondary.dark,
      },
    },
  },
}));

export default StyledTextField;
