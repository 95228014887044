import { Stack } from "@mui/material";
import React from "react";
import Title from "../../common/Title";

import { ReactComponent as IconPotionBlue } from "../../../assets/icon-potion-blue.svg";
import { ImageThumbnail } from "..";
import PixelateExpander from "./PixelateExpander";
import RemoveBgExpander from "./RemoveBgExpander";
import RevibeExpander from "./RevibeExpander";

type RightPanelProps = {
  isLoading: boolean;
  selectedImage: ImageThumbnail | null;
  onProcessingStart: () => void;
  onProcessingEnd: (result?: ImageThumbnail[]) => void;
};

const RightPanel = ({
  isLoading,
  onProcessingStart,
  onProcessingEnd,
  selectedImage,
}: RightPanelProps) => {
  const [expanded, setExpanded] = React.useState("");

  const handleChangeExpanded = (expander: string) => (isExpanded: boolean) => {
    setExpanded(isExpanded ? expander : "");
  };

  return (
    <Stack spacing={4} sx={{ minWidth: "330px" }}>
      <Title Icon={<IconPotionBlue />} title="Modify" />
      <Stack spacing={4} sx={{ mt: 4 }}>
        <PixelateExpander
          disabled={isLoading}
          selectedImage={selectedImage}
          expanded={expanded === "Pixelate"}
          onExpand={handleChangeExpanded("Pixelate")}
          onProcessingStart={onProcessingStart}
          onProcessingEnd={onProcessingEnd}
        />
        <RevibeExpander
          disabled={isLoading}
          selectedImage={selectedImage}
          expanded={expanded === "Re-Vibe"}
          onExpand={handleChangeExpanded("Re-Vibe")}
          onProcessingStart={onProcessingStart}
          onProcessingEnd={onProcessingEnd}
        />
        <RemoveBgExpander
          disabled={isLoading}
          selectedImage={selectedImage}
          expanded={expanded === "Remove Background"}
          onExpand={handleChangeExpanded("Remove Background")}
          onProcessingStart={onProcessingStart}
          onProcessingEnd={onProcessingEnd}
        />
      </Stack>
    </Stack>
  );
};

export default RightPanel;
