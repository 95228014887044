import { Box, Typography } from "@mui/material";
import StyledTextField from "../../common/StyledTextField";

type PromptProps = {
  text: string;
  onChange: (text: string) => void;
  disabled: boolean;
};

const Prompt = ({ text, onChange, disabled }: PromptProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
      <Typography
        color="secondary.light"
        fontSize="14px"
        fontWeight="fontWeightMedium"
      >
        Prompt
      </Typography>
      <StyledTextField
        disabled={disabled}
        value={text}
        onChange={handleChange}
        multiline={true}
        placeholder="What do you want to see? You can use a single word or a full sentence."
        sx={{ mt: 2, textarea: { minHeight: "110px" } }}
      />
    </Box>
  );
};

export default Prompt;
