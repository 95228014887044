import { Menu, MenuProps } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 16,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "white",
    backgroundColor: "#1E1C41",
    boxShadow: `0 0 10px ${alpha(theme.palette.pink1.main, 0.5)}`,
    "& .MuiList-padding": {
      padding: 0,
    },
    "& .MuiMenuItem-root": {
      padding: 16,
      "&:hover": {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
  ".MuiMenuItem-root+.MuiDivider-root": {
    margin: 0,
  },
}));

export default StyledMenu;
