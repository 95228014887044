import { Slider } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import IconSliderThumb from "../../assets/icon-gem-pink.svg";

const StyledSlider = styled(Slider)(({ theme }) => ({
  "& .MuiSlider-root": {
    color: theme.palette.pink1.main,
  },
  "&.Mui-disabled .MuiSlider-root": {
    color: theme.palette.pink1.dark,
  },
  "& .MuiSlider-track": {
    color: theme.palette.pink1.main,
  },
  "&.Mui-disabled .MuiSlider-track": {
    color: theme.palette.pink1.dark,
  },
  "& .MuiSlider-rail": {
    color: theme.palette.secondary.main,
  },
  "&.Mui-disabled .MuiSlider-rail": {
    color: theme.palette.secondary.dark,
  },
  "& .MuiSlider-thumb": {
    backgroundImage: `url(${IconSliderThumb})`,
    backgroundSize: "20px",
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.pink1.main, 0.16)}`,
    },
  },
}));

export default StyledSlider;
