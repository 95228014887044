import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

type StyledCardProps = {
  image: string;
  label: string;
  selected?: boolean;
  onClick?: () => void;
};

const PotionCard = ({ image, label, selected, onClick }: StyledCardProps) => {
  return (
    <Card
      variant="outlined"
      sx={(theme) => ({
        maxWidth: 150,
        borderRadius: 2,
        border: `1px solid ${
          selected
            ? theme.palette.emphasized.main
            : theme.palette.secondary.main
        }`,
      })}
      onClick={onClick}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height={106}
          src={image}
          sx={(theme) => ({
            borderBottom: `1px solid ${
              selected
                ? theme.palette.emphasized.main
                : theme.palette.secondary.main
            }`,
          })}
        />
        <CardContent
          sx={(theme) => ({
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
            height: 44,
            backgroundColor: selected
              ? theme.palette.emphasized.main
              : "initial",
          })}
        >
          <Typography
            color={selected ? "white" : "secondary"}
            fontSize={12}
            textAlign="center"
          >
            {label}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default PotionCard;
