import { alpha, Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useResizeDetector } from "react-resize-detector";
import { ReactComponent as IconDownload } from "../../../assets/icon-download.svg";
import { ReactComponent as IconOpenOriginal } from "../../../assets/icon-open-original.svg";
import { ImageThumbnail } from "..";
import StyledTooltip from "../../common/StyledTooltip";

type DropzoneImageProps = {
  isLoading: boolean;
  image: ImageThumbnail;
  selected: boolean;
  onClick: () => void;
  justifyContent?: string;
  alignItems?: string;
};
const DropzoneImage = ({
  isLoading,
  image,
  selected,
  onClick,
  justifyContent = "center",
  alignItems = "center",
}: DropzoneImageProps) => {
  const aspectRatioText = `${image.img.width}x${image.img.height}`;
  const {
    width: containerWidth,
    height: containerHeight,
    ref,
  } = useResizeDetector<HTMLDivElement>();
  const originalAspectRatio = React.useMemo(
    () => image.img.width / image.img.height,
    [image]
  );

  let imgHeight = 0,
    imgWidth = 0,
    overlayOffsetY = 0,
    overlayOffsetX = 0;
  if (containerHeight && containerWidth) {
    imgHeight =
      containerHeight * originalAspectRatio > containerWidth
        ? containerWidth / originalAspectRatio
        : containerHeight;
    imgWidth = imgHeight * originalAspectRatio;
    if (justifyContent === "center") {
      overlayOffsetX = Math.abs(containerWidth - imgWidth) / 2;
    } else if (justifyContent === "flex-start") {
      overlayOffsetX = 0;
    } else if (justifyContent === "flex-end") {
      overlayOffsetX = Math.abs(containerWidth - imgWidth);
    }

    if (alignItems === "center") {
      overlayOffsetY = Math.abs(containerHeight - imgHeight) / 2;
    } else if (alignItems === "flex-start") {
      overlayOffsetY = 0;
    } else if (alignItems === "flex-end") {
      overlayOffsetY = Math.abs(containerHeight - imgHeight);
    }
  }

  const handleClickDownload = async (e: any) => {
    e.stopPropagation();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(image.blob);
    const filename = `${image.name}_${image.uuid.slice(0, 8)}`
      .replace(/[^a-z0-9]/gi, "_")
      .toLowerCase();
    link.download = `${filename}.${image.ext}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClickOpenOriginal = (e: any) => {
    e.stopPropagation();
    var w = window.open("");
    w?.document.write(image.img.outerHTML);
  };

  return (
    <Box
      ref={ref}
      sx={(theme) => ({
        position: "relative",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent,
        alignItems: "center",
      })}
    >
      <Box
        sx={(theme) => ({
          cursor: "pointer",
          height: imgHeight,
          width: imgWidth,
          overflow: "hidden",
          border: `${selected ? "3" : "0"}px solid ${
            theme.palette.emphasized.main
          }`,
        })}
      >
        <Box
          component="img"
          src={image.img.src}
          sx={(theme) => ({
            height: "100%",
            width: "100%",
            filter: isLoading ? "blur(5px)" : "",
          })}
        />
      </Box>
      <Box
        sx={(theme) => ({
          opacity: 0,
          position: "absolute",
          backgroundColor: alpha(theme.palette.pink1.main, 0.2),
          ":hover": {
            opacity: 1,
          },
          height: imgHeight,
          width: imgWidth,
          cursor: "pointer",
          transition: "opacity 200ms",
          top: overlayOffsetY,
          left: overlayOffsetX,
        })}
        onClick={onClick}
      >
        <Typography
          color="white"
          fontSize="12px"
          sx={{
            px: 1,
            py: 0.5,
            backgroundColor: "primary.main",
            borderRadius: 1,
            position: "absolute",
            left: "8px",
            top: "8px",
          }}
        >
          {aspectRatioText}
        </Typography>
        <Box
          sx={{
            position: "absolute",
            right: "8px",
            bottom: "8px",
            display: "flex",
          }}
        >
          <Box
            sx={{
              color: "white",
              display: "flex",
              p: 0.5,
              backgroundColor: "primary.main",
              borderRadius: 1,
              mr: 1,
              svg: {
                height: "24px",
                width: "24px",
              },
            }}
          >
            <StyledTooltip
              title="Open in new tab"
              sx={{ ".MuiTooltip-tooltip": { p: 0.5 } }}
            >
              <IconOpenOriginal onClick={handleClickOpenOriginal} />
            </StyledTooltip>
          </Box>
          <Box
            sx={{
              color: "white",
              display: "flex",
              p: 0.5,
              backgroundColor: "primary.main",
              borderRadius: 1,
              svg: {
                height: "24px",
                width: "24px",
              },
            }}
          >
            <StyledTooltip
              title="Download"
              sx={{ ".MuiTooltip-tooltip": { p: 0.5 } }}
            >
              <IconDownload onClick={handleClickDownload} />
            </StyledTooltip>
          </Box>
        </Box>
      </Box>
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: imgHeight,
            width: imgWidth,
            top: overlayOffsetY,
            left: overlayOffsetX,
          }}
        >
          <CircularProgress color="emphasized" />
        </Box>
      )}
    </Box>
  );
};

export default DropzoneImage;
