import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import {
  Price,
  createCheckoutSession,
  getCurrentUserSubscriptions,
  getProducts,
  onCurrentUserSubscriptionUpdate,
} from "@stripe/firestore-stripe-payments";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import {
  httpsCallable
} from "firebase/functions";
import React from "react";
import { useLocation } from "react-router-dom";
import Stripe from "stripe";
import { DISCORD_LINK } from "../../constants";
import { AuthContext } from "../../contexts/AuthContext";
import { ErrorContext } from "../../contexts/ErrorContext";
import {
  firebaseFirestore,
  firebaseFunctions,
  stripePayments,
} from "../../firebase";
import { FAQ, FAQS } from "./FAQ";
import { SubscriptionDescription } from "./SubscriptionDescription";

const padding = 40;

// Shimming in the type for the Rewardful global added in index.html
declare global {
  interface Window {
    Rewardful?: {
      referral?: string;
    };
  }
}

const SubscriptionPage = () => {
  const { user, openSessionDialog } = React.useContext(AuthContext);
  const { openErrorDialog } = React.useContext(ErrorContext);
  const [billingInterval, setBillingInterval] = React.useState("month");
  const queryClient = useQueryClient();
  const location = useLocation();

  const handleClickDiscord = () => {
    window.open(DISCORD_LINK, "_blank");
  };

  const productsQuery = useQuery({
    queryKey: ["products"],
    queryFn: () =>
      getProducts(stripePayments, {
        includePrices: true,
        activeOnly: true,
      }),
  });

  const subscriptionsQuery = useQuery({
    queryKey: ["subscriptions", user],
    queryFn: () =>
      getCurrentUserSubscriptions(stripePayments, { status: "active" }),
    enabled: !!user,
  });

  const generationsQuery = useQuery({
    queryKey: ["generations", user],
    queryFn: () => {
      const functionRef = httpsCallable<
        undefined,
        { count: number; max: number }
      >(firebaseFunctions, "getGenerations");
      return functionRef();
    },
    enabled: !!user,
  });

  function getClientReferenceId() {
    return (
      (window.Rewardful && window.Rewardful.referral) ||
      "checkout_" + new Date().getTime()
    );
  }

  const checkoutMutation = useMutation({
    mutationFn: (price: Price) =>
      createCheckoutSession(stripePayments, {
        price: price.id,
        success_url: `${window.location.origin}/subscription`,
        cancel_url: `${window.location.origin}/subscription/${location.search}`,
        allow_promotion_codes: true,
        // @ts-expect-error
        payment_method_collection: "if_required",
        client_reference_id: getClientReferenceId(),
      }),
    onSuccess: (data) => {
      window.location.assign(data.url);
    },
  });

  const customerPortalMutation = useMutation({
    mutationFn: () => {
      const functionRef = httpsCallable<
        // Custom type for the firebase function I looked up in the source code
        { returnUrl: string; locale?: string; configuration?: any },
        Stripe.BillingPortal.Session
      >(firebaseFunctions, process.env.REACT_APP_CREATE_PORTAL_LINK_URL || "");
      return functionRef({
        returnUrl: `${window.location.origin}/subscription/${location.search}`,
      });
    },
    onSuccess: (data) => {
      window.location.assign(data.data.url);
    },
  });

  React.useEffect(() => {
    if (user) {
      // Returns an unsubscribe method
      const unsubSubscriptionUpdates = onCurrentUserSubscriptionUpdate(
        stripePayments,
        (snapshot) => {
          queryClient.invalidateQueries(["subscriptions"]);
        }
      );

      const unsubGenerationUpdates = onSnapshot(
        query(
          collection(firebaseFirestore, "generations"),
          where("owner_uid", "==", user.uid)
        ),
        (snapshot) => {
          queryClient.invalidateQueries(["generations"]);
        }
      );

      return () => {
        unsubSubscriptionUpdates();
        unsubGenerationUpdates();
      };
    }
  }, [user, queryClient]);

  const handleClickSelectPlan = async (price: Price | undefined) => {
    if (price === undefined) {
      openErrorDialog({
        title: "Subscription Error",
        message: "An error occurred. Please try again later.",
      });
      return;
    }
    if (!user) {
      const loginResult = await openSessionDialog();

      if (loginResult === null) {
        // User didn't log in, don't request a checkout session
        return;
      }
    }

    if (subscriptionsQuery.data && subscriptionsQuery.data.length > 0) {
      // User has a subscription so open the full management pane
      customerPortalMutation.mutate();
    } else {
      // User doesn't have a subscription so open the checkout pane
      checkoutMutation.mutate(price);
    }
  };

  const handleClickManageData = () => {
    customerPortalMutation.mutate();
  };

  const hasSubscription =
    subscriptionsQuery.data && subscriptionsQuery.data.length > 0;
  const subscriptionName =
    subscriptionsQuery.data &&
    (!hasSubscription
      ? "Free Plan"
      : productsQuery.data &&
        productsQuery.data.find(
          (product) => product.id === subscriptionsQuery.data[0]?.product
        )?.name);

  const numGenerations = generationsQuery.data?.data.count;
  const allowedGenerations = generationsQuery.data?.data.max;
  let generationsText = "";
  let generationsWidthPercent = 0;

  if (allowedGenerations === Number.MAX_SAFE_INTEGER) {
    generationsText = "Unlimited generations";
    generationsWidthPercent = 100;
  } else if (
    typeof allowedGenerations === "number" &&
    typeof numGenerations === "number"
  ) {
    const remainingGenerations = Math.max(
      allowedGenerations - numGenerations,
      0
    );
    generationsText = `${remainingGenerations} generations left`;
    generationsWidthPercent = (remainingGenerations / allowedGenerations) * 100;
  }

  return (
    <Box
      sx={{
        p: 6,
        minWidth: "750px",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={checkoutMutation.isLoading || customerPortalMutation.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack
        spacing={6}
        sx={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          fontSize="36px"
          color="white"
          fontWeight="fontWeightBold"
          display="flex"
          flexDirection="column"
          component="div"
        >
          Manage Subscription
          {user && hasSubscription && (
            <Button
              onClick={handleClickManageData}
              variant="contained"
              color="inherit"
              sx={(theme) => ({ color: theme.palette.primary.main, mt: 1 })}
            >
              Manage billing and payments on Stripe
            </Button>
          )}
        </Typography>

        {subscriptionsQuery.isLoading || productsQuery.isLoading ? (
          <Skeleton
            sx={(theme) => ({
              width: "100%",
              height: "88px",
              bgcolor: theme.palette.secondary.main,
              maxWidth: "1264px",
              "&.MuiSkeleton-root": { transform: "scale(1,1)" },
            })}
          />
        ) : (
          <Box
            sx={{
              borderRadius: 2,
              width: "100%",
              py: 2,
              px: `${padding}px`,
              position: "relative",
              boxSizing: "border-box",
              maxWidth: "1264px",
            }}
            bgcolor="secondary.dark"
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", mb: 2 }}>
                {subscriptionsQuery.isLoading ? (
                  <CircularProgress
                    size="12px"
                    sx={{ color: "white", mr: 0.5 }}
                  />
                ) : (
                  <>
                    <Typography
                      fontSize="16px"
                      fontWeight="fontWeightMedium"
                      color="white"
                      mr="4px"
                    >
                      Currently you are using a
                    </Typography>
                    <Typography
                      fontSize="16px"
                      fontWeight="fontWeightMedium"
                      sx={(theme) => ({
                        color: theme.palette.emphasized.main,
                        display: "inline-block",
                      })}
                    >
                      {subscriptionName}
                    </Typography>
                  </>
                )}
              </Box>
              <Typography fontSize="16px" color="white">
                {generationsQuery.isFetching && (
                  <CircularProgress
                    size="12px"
                    sx={{ color: "white", mr: 0.5 }}
                  />
                )}
                {!generationsQuery.isLoading && generationsText}
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Box
                sx={(theme) => ({
                  height: "8px",
                  width: `calc(100% - ${2 * padding}px)`,
                  borderRadius: "4px",
                  backgroundColor: theme.palette.primary.main,
                  position: "absolute",
                })}
              >
                <Box
                  sx={(theme) => ({
                    height: "8px",
                    width: `${generationsWidthPercent}%`,
                    borderRadius: "4px",
                    backgroundColor: theme.palette.pink1.main,
                  })}
                />
              </Box>
            </Box>
          </Box>
        )}
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            color={billingInterval === "year" ? "emphasized" : "primary"}
            variant="contained"
            onClick={() => setBillingInterval("year")}
            sx={{
              textTransform: "initial",
              borderRadius: billingInterval === "year" ? "4px" : "4px 0 0 4px",
              mr: billingInterval === "month" ? "-4px" : 0,
              zIndex: billingInterval === "month" ? 1 : 2,
            }}
          >
            Yearly Billing
            <Typography
              fontSize="14px"
              fontWeight="fontWeightBold"
              ml={1}
              sx={(theme) => ({
                color:
                  billingInterval === "year"
                    ? theme.palette.primary.main
                    : theme.palette.emphasized.main,
              })}
            >
              20% OFF
            </Typography>
          </Button>
          <Button
            color={billingInterval === "month" ? "emphasized" : "primary"}
            variant="contained"
            onClick={() => setBillingInterval("month")}
            sx={{
              textTransform: "initial",
              borderRadius: billingInterval === "month" ? "4px" : "0 4px 4px 0",
              ml: billingInterval === "year" ? "-4px" : 0,
              zIndex: billingInterval === "year" ? 1 : 2,
            }}
          >
            Monthly Billing
          </Button>
        </Box>
        {(productsQuery.isLoading || subscriptionsQuery.isLoading) && (
          <Stack
            direction="row"
            spacing={4}
            sx={{
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Skeleton
              variant="rounded"
              width={400}
              height={245}
              sx={(theme) => ({ bgcolor: theme.palette.secondary.dark })}
            />
            <Skeleton
              variant="rounded"
              width={400}
              height={245}
              sx={(theme) => ({ bgcolor: theme.palette.secondary.dark })}
            />
            <Skeleton
              variant="rounded"
              width={400}
              height={245}
              sx={(theme) => ({ bgcolor: theme.palette.secondary.dark })}
            />
          </Stack>
        )}
        {productsQuery.data && (
          <Stack
            direction="row"
            spacing={4}
            sx={{
              width: "100%",
              justifyContent: "center",
            }}
          >
            {productsQuery.data.map((product) => (
              <SubscriptionDescription
                key={product.id}
                billingInterval={billingInterval}
                product={product}
                active={
                  !user
                    ? product.name === "Free Plan"
                    : !subscriptionsQuery.data ||
                      subscriptionsQuery.data[0]?.product === product.id
                }
                activeSubscription={
                  user && subscriptionsQuery.data
                    ? subscriptionsQuery?.data[0]
                    : null
                }
                onClickSelectPlan={handleClickSelectPlan}
              />
            ))}
          </Stack>
        )}
        <Typography fontSize="36px" fontWeight="fontWeightBold" color="white">
          Frequently Asked Questions
        </Typography>
        <Typography
          fontSize="16px"
          color="white"
          sx={{ width: "350px", textAlign: "center" }}
          component="div"
        >
          Can’t find the answer you’re looking for? Join our
          <Typography
            sx={(theme) => ({
              mx: 1,
              color: theme.palette.secondary.main,
              cursor: "pointer",
              display: "inline-block",
            })}
            onClick={handleClickDiscord}
          >
            Discord
          </Typography>
          and chat with us there!
        </Typography>
        <Box
          sx={{
            width: "800px",
            "& .MuiPaper-root:not(:first-of-type)": {
              mt: 1,
            },
          }}
        >
          {FAQS.map((faq) => (
            <FAQ key={faq.title} {...faq} />
          ))}
        </Box>
      </Stack>
    </Box>
  );
};

export default SubscriptionPage;
