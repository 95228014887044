import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import IconSliderThumb from "../../assets/icon-gem-pink.svg";

const StyledSwitch = styled(Switch)(({ theme }) => ({
  padding: 4,
  "& .Mui-checked": {
    "& .MuiSwitch-thumb": {
      backgroundImage: `url(${IconSliderThumb})`,
      backgroundSize: 23,
      backgroundColor: "transparent",
      backgroundPosition: "center",
    },
    "& + .MuiSwitch-track": {
      background: "linear-gradient(to right, #F465AE, #AD4DDA)",
      border: 0,
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.secondary.main,
  },
  "& .MuiSwitch-track": {
    backgroundColor: "transparent",
    borderRadius: 16,
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
  },
}));

export default StyledSwitch;
