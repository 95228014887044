import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
} from "@mui/material";
import RotatingArrowIcon from "../common/RotatingArrowIcon";

const SPECIAL_PURPLE = "#191835";

type FAQProps = {
  title: string;
  description: React.ReactElement;
};

export function FAQ({ title, description }: FAQProps) {
  return (
    <Accordion
      sx={(theme) => ({
        color: theme.palette.secondary.main,
        borderRadius: "4px",
      })}
    >
      <AccordionSummary
        expandIcon={<RotatingArrowIcon degrees={90} />}
        sx={(theme) => ({
          backgroundColor: SPECIAL_PURPLE,
          " .MuiAccordionSummary-expandIconWrapper": { color: "inherit" },
          "&.Mui-expanded": {
            borderBottom: `1px solid ${theme.palette.primary.dark}`,
            borderRadius: "4px 4px 0 0",
          },
          borderRadius: "4px 4px 0 0",
        })}
      >
        <Typography fontWeight="fontWeightBold">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={(theme) => ({
          borderRadius: "0 0 4px 4px",
          backgroundColor: SPECIAL_PURPLE,
          "&.Mui-expanded": {},
        })}
      >
        {description}
      </AccordionDetails>
    </Accordion>
  );
}

export const FAQS = [
  {
    title: "What are the available subscription plans?",
    description: (
      <Box>
        <Typography>We offer three subscription plans:</Typography>
        <Typography>- Free Plan</Typography>
        <Typography>- Standard Plan ($30/month)</Typography>
        <Typography>- Pro Plan ($60/month)</Typography>
      </Box>
    ),
  },
  {
    title: "What are the benefits of the Free Plan?",
    description: (
      <Box>
        <Typography>The Free Plan includes:</Typography>
        <Typography>- Up to 10 generations per day</Typography>
      </Box>
    ),
  },
  {
    title: "What are the benefits of the Standard Plan?",
    description: (
      <Box>
        <Typography>The Standard Plan ($30/month) includes:</Typography>
        <Typography>- 1000 generations per month</Typography>
      </Box>
    ),
  },
  {
    title: "What are the benefits of the Pro Plan?",
    description: (
      <Box>
        <Typography>The Pro Plan ($60/month) includes:</Typography>
        <Typography>- Unlimited generations</Typography>
      </Box>
    ),
  },
  {
    title: "How can I change my subscription plan?",
    description: (
      <Box>
        To change your subscription plan, simply click the "Select Plan" button
        next to the desired plan on the "Subscription" page. Or, if you already
        subscribed, click on the "Manage Billing and Payments on Stripe" button
        on the "Subscription" page.
      </Box>
    ),
  },
  {
    title: "Are there any discounts available?",
    description: <Box>Yes, we offer a 20% discount for yearly billing.</Box>,
  },
  {
    title: "How can I check how many daily generations I have left?",
    description: (
      <Box>
        You can find the remaining daily generations on the "Subscription" page.
      </Box>
    ),
  },
  {
    title:
      "What happens if I exceed my daily generation limit on the Free Plan?",
    description: (
      <Box>
        Once you exceed your daily limit, you will be unable to generate more
        content until the next day when your limit resets.
      </Box>
    ),
  },
  {
    title: "Can I switch between Monthly and Yearly billing?",
    description: (
      <Box>
        Yes, you can switch between monthly and yearly billing at any time. To
        do so, select the desired billing option on the "Subscription" page.
      </Box>
    ),
  },
  {
    title: " How can I cancel my subscription?",
    description: (
      <Box>
        To cancel your subscription, click on the "Manage Billing and Payments
        on Stripe" button on the "Subscription" page and then cancel your
        subscription by clicking on "Cancel".
      </Box>
    ),
  },
];
