import { CircularProgress, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ImageThumbnail } from "..";
import useGamingAssetsApi, {
  API_IMAGE_TYPE,
  DreamboothRequest,
} from "../../../apis/useGamingAssetsApi";
import { ReactComponent as IconPotion } from "../../../assets/icon-potion-pink.svg";
import { PotionModel } from "../../../assets/model-data";
import { DEFAULT_ERROR, ErrorContext } from "../../../contexts/ErrorContext";
import StyledButton from "../../common/StyledButton";
import Title from "../../common/Title";
import NumImagesChooser from "./NumImagesChooser";
import PotionModelChooser from "./PotionModelChooser";
import Prompt from "./Prompt";
import SquareSizeSlider from "./SquareSizeSlider";

const MIN_IMAGE_SIZE = 64;
const MAX_IMAGE_SIZE = 960;
const DEFAULT_IMAGE_SIZE = 512;
const STEP_SIZE = 64;

type LeftPanelProps = {
  isLoading: boolean;
  onGenerateStart: () => void;
  onGenerateEnd: (images?: ImageThumbnail[]) => void;
};

const LeftPanel = ({
  isLoading,
  onGenerateStart,
  onGenerateEnd,
}: LeftPanelProps) => {
  const { openErrorDialog } = React.useContext(ErrorContext);
  const [modelName, setModelName] = React.useState("");
  const [promptText, setPromptText] = React.useState("");
  const [size, setSize] = React.useState(DEFAULT_IMAGE_SIZE);
  const [numberOfOutputs, setNumberOfOutputs] = React.useState(1);

  const api = useGamingAssetsApi();
  const { mutate } = useMutation({
    mutationFn: (newRequest: DreamboothRequest) =>
      api.getDreamboothImages(newRequest, onGenerateStart),
    onSuccess: async (data, variables) => {
      let formatted: ImageThumbnail[] = [];
      for (let i = 0; i < data.data.images.length; i++) {
        const img = new Image();
        img.src = data.data.images[i];
        img.onload = async () => {
          const res = await fetch(img.src);
          formatted.push({
            blob: await res.blob(),
            img,
            name: variables.model_name,
            ext: API_IMAGE_TYPE,
            uuid: uuidv4(),
          });
          if (formatted.length === data.data.images.length) {
            onGenerateEnd(formatted);
          }
        };
      }
    },
    onError: (error: AxiosError) => {
      if (
        error?.response?.status === 403 &&
        error?.response?.data === "Out of credits"
      ) {
        openErrorDialog({
          title: "Out of credits!",
          message: (
            <Typography
              color="white"
              fontSize="14px"
              alignSelf="center"
              component="div"
            >
              Head to the{" "}
              <Typography
                color="pink2.main"
                fontSize="inherit"
                display="inline-block"
                fontWeight="fontWeightMedium"
              >
                <Link to="/subscription" style={{ color: "inherit" }}>
                  Subscription
                </Link>
              </Typography>{" "}
              page to update your plan or send an email to{" "}
              <Typography
                color="pink2.main"
                fontSize="inherit"
                display="inline-block"
                sx={{ textDecoration: "underline" }}
                fontWeight="fontWeightMedium"
              >
                support@rosebudai.com
              </Typography>{" "}
              if you think this is an error.
            </Typography>
          ),
        });
      } else {
        openErrorDialog(DEFAULT_ERROR);
      }
      onGenerateEnd();
    },
  });

  const handleChangeModel = (potionModel: PotionModel) => {
    setModelName(potionModel.name);
  };

  const handleChangePrompt = (prompt: string) => {
    setPromptText(prompt);
  };

  const handleChangeImageSize = (size: number) => {
    setSize(size);
  };

  const handleChangeNumberOfOutputs = (size: number) => {
    setNumberOfOutputs(size);
  };

  const handleClickCreate = async () => {
    mutate({
      model_name: modelName,
      prompt: promptText,
      height: size,
      width: size,
      num_outputs: numberOfOutputs,
    });
  };

  return (
    <Stack spacing={4} sx={{ minWidth: "314px" }}>
      <Title Icon={<IconPotion />} title="Potion Models" />
      <PotionModelChooser
        disabled={isLoading}
        onClickModel={handleChangeModel}
        onClickSpell={handleChangePrompt}
      />
      <Prompt
        disabled={isLoading}
        text={promptText}
        onChange={handleChangePrompt}
      />
      <SquareSizeSlider
        disabled={isLoading}
        value={size}
        label="Image Size"
        min={MIN_IMAGE_SIZE}
        max={MAX_IMAGE_SIZE}
        stepSize={STEP_SIZE}
        onChange={handleChangeImageSize}
      />
      <NumImagesChooser
        disabled={isLoading}
        value={numberOfOutputs}
        onChange={handleChangeNumberOfOutputs}
      />
      <StyledButton
        disabled={!promptText || !modelName || isLoading}
        onClick={handleClickCreate}
      >
        {isLoading ? (
          <CircularProgress color="secondary" size="1.5rem" />
        ) : (
          "Create"
        )}
      </StyledButton>
    </Stack>
  );
};

export default LeftPanel;
