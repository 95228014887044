import { Typography } from "@mui/material";

type WidgetLabelProps = {
  label: string;
  width?: number;
};

const WidgetLabel = ({ label, width }: WidgetLabelProps) => {
  return (
    <Typography
      color="secondary.light"
      fontWeight="fontWeightMedium"
      fontSize="14px"
      width={width || 150}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {label}
    </Typography>
  );
};

export default WidgetLabel;
