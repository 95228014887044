import { Box, Typography } from "@mui/material";

type TitleProps = {
  Icon: React.ReactNode;
  title: string;
};
const Title = ({ Icon, title }: TitleProps) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {Icon}
      <Typography
        variant="h5"
        color="secondary.light"
        fontWeight="fontWeightBold"
        fontSize={20}
        sx={{ ml: 1 }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default Title;
