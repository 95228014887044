import { Box, Typography, Button, ButtonProps } from "@mui/material";
import { ReactComponent as IconCheckmarkPink } from "../../../assets/icon-checkmark-pink.svg";

type NumImagesButtonProps = {
  selected: boolean;
} & ButtonProps;

const NumImagesButton = ({
  selected,
  children,
  ...otherProps
}: NumImagesButtonProps) => {
  return (
    <Button
      variant="outlined"
      color="secondary"
      sx={(theme) => ({
        borderRadius: 2,
        bgcolor: selected ? "emphasizedBg.main" : "initial",
        "&.Mui-disabled": {
          borderColor: theme.palette.secondary.dark,
          color: theme.palette.secondary.dark,
        },
      })}
      {...otherProps}
    >
      {selected && <IconCheckmarkPink />}
      <Typography fontSize="12px" sx={{ ml: selected ? 1 : 0 }}>
        {children}
      </Typography>
    </Button>
  );
};

type NumImagesChooserProps = {
  value: number;
  onChange: (value: number) => void;
  disabled: boolean;
};

const NumImagesChooser = ({
  value,
  onChange,
  disabled,
}: NumImagesChooserProps) => {
  const handleClick = (value: number) => {
    onChange(value);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 4 }}>
      <Box sx={{ display: "flex" }}>
        <Typography
          color="secondary.light"
          fontSize="14px"
          fontWeight="fontWeightMedium"
        >
          Number of images
        </Typography>
        <Typography color="white" fontSize="14px" ml={2}>
          {value}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {[1, 2, 3, 4].map((num) => (
          <NumImagesButton
            key={num}
            disabled={disabled}
            selected={value === num}
            onClick={() => handleClick(num)}
          >
            {num}
          </NumImagesButton>
        ))}
      </Box>
    </Box>
  );
};

export default NumImagesChooser;
