import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.emphasizedBg.main,
    color: "white",
    maxWidth: 280,
    fontSize: 12,
    border: `1px solid ${theme.palette.secondary.main}`,
    padding: 12,
    borderRadius: 8,
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    backgroundColor: theme.palette.emphasizedBg.main,
    border: `1px solid ${theme.palette.secondary.main}`,
  },
}));

export default StyledTooltip;
