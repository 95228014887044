import React from "react";
import { AuthContext } from "../contexts/AuthContext";
import axios, { AxiosRequestConfig, ResponseType } from "axios";
import { ImageThumbnail } from "../components/AssetDesigner";
import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "../firebase";

export const API_IMAGE_TYPE = "png";

export type DreamboothResponse = {
  images: Array<string>;
};

export type DreamboothRequest = {
  model_name: string;
  prompt: string;
  width: number;
  height: number;
  num_outputs: number;
};

export type RevibeMask = {
  visualize: string;
  mask: string;
};

export type RevibeMaskResponseSchema = {
  foreground: RevibeMask;
  background: RevibeMask;
  all_image: RevibeMask;
};

export type PixelateImageOptions = {
  imageThumbnail: ImageThumbnail;
  pixelationValue: number;
  height: number;
  width: number;
  addBorders: boolean;
};

export type RevibeImageOptions = {
  imageThumbnail: ImageThumbnail;
  maskBlob: Blob;
  prompt: string;
};

export type RemoveBackgroundOptions = {
  imageThumbnail: ImageThumbnail;
};

const useGamingAssetsApi = () => {
  const { user, openSessionDialog } = React.useContext(AuthContext);

  const fetchAuthenticated = async <T>(
    config: AxiosRequestConfig,
    onFetchStart: () => void
  ) => {
    let token;
    if (user === null) {
      const loginResult = await openSessionDialog();

      if (loginResult === null) {
        throw new Error("User is not signed in");
      } else {
        token = await loginResult.getIdToken();
      }
    } else {
      token = await user.getIdToken();
    }

    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };

    onFetchStart();
    return await axios<T>(config);
  };

  const getDreamboothImages = async (
    data: DreamboothRequest,
    onFetchStart: () => void
  ) => {
    const config = {
      url: process.env.REACT_APP_DREAMBOOTH_INFERENCE_URL,
      method: "POST",
      data,
    };

    logEvent(firebaseAnalytics, "create_button_click", data);

    return await fetchAuthenticated<DreamboothResponse>(config, onFetchStart);
  };

  const removeBackground = async (
    { imageThumbnail }: RemoveBackgroundOptions,
    onFetchStart: () => void
  ) => {
    const formData = new FormData();
    formData.append("image", imageThumbnail.blob);

    const config = {
      url: process.env.REACT_APP_REMOVE_BG_URL,
      method: "POST",
      data: formData,
      responseType: "blob" as ResponseType,
    };

    logEvent(firebaseAnalytics, "remove_bg_button_click");
    const response = await fetchAuthenticated<Blob>(config, onFetchStart);
    return response;
  };

  const pixelateImage = async (
    {
      imageThumbnail,
      pixelationValue,
      height,
      width,
      addBorders,
    }: PixelateImageOptions,
    onFetchStart: () => void
  ) => {
    const formData = new FormData();
    formData.append("image", imageThumbnail.blob);

    const data = {
      pixel_size: pixelationValue,
      height,
      width,
      add_borders: String(addBorders),
    };

    formData.append("json", JSON.stringify(data));

    const config = {
      url: process.env.REACT_APP_PIXELISATION_URL,
      method: "POST",
      data: formData,
      responseType: "blob" as ResponseType,
    };

    logEvent(firebaseAnalytics, "pixelate_button_click", data);
    return await fetchAuthenticated<Blob>(config, onFetchStart);
  };

  const b64toBlob = (
    b64Data: string,
    contentType: string,
    sliceSize: number
  ) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const getRevibeMasks = async (imageBlob: Blob, onFetchStart: () => void) => {
    const formData = new FormData();
    formData.append("image", imageBlob);

    const config = {
      url: process.env.REACT_APP_REVIBE_MASKS_URL,
      method: "POST",
      data: formData,
    };

    const response = await fetchAuthenticated<RevibeMaskResponseSchema>(
      config,
      onFetchStart
    );

    // convert response objects to blob
    const foregroundMask = {
      name: "Object",
      src: response.data.foreground.visualize,
      visualize: b64toBlob(
        response.data.foreground.visualize,
        "image/png",
        512
      ),
      mask: b64toBlob(response.data.foreground.mask, "image/png", 512),
    };

    const backgroundMask = {
      name: "Background",
      src: response.data.background.visualize,
      visualize: b64toBlob(
        response.data.background.visualize,
        "image/png",
        512
      ),
      mask: b64toBlob(response.data.background.mask, "image/png", 512),
    };

    const allImageMask = {
      name: "All",
      src: response.data.all_image.visualize,
      visualize: b64toBlob(response.data.all_image.visualize, "image/png", 512),
      mask: b64toBlob(response.data.all_image.mask, "image/png", 512),
    };

    return [foregroundMask, backgroundMask, allImageMask];
  };

  const revibeImage = async (
    { imageThumbnail, maskBlob, prompt }: RevibeImageOptions,
    onFetchStart: () => void
  ) => {
    const formData = new FormData();
    formData.append("image", imageThumbnail.blob);
    formData.append("mask", maskBlob);

    const data = {
      text_prompt: prompt,
    };
    formData.append("json", JSON.stringify(data));

    const config = {
      url: process.env.REACT_APP_REVIBE_URL,
      method: "POST",
      data: formData,
      responseType: "blob" as ResponseType,
    };

    logEvent(firebaseAnalytics, "revibe_button_click", data);
    return await fetchAuthenticated<Blob>(config, onFetchStart);
  };

  return {
    getDreamboothImages,
    pixelateImage,
    getRevibeMasks,
    revibeImage,
    removeBackground,
  };
};

export default useGamingAssetsApi;
