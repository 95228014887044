import { Box, darken, Typography } from "@mui/material";

type RevibeOptionProps = {
  image: string;
  label: string;
  selected: boolean;
  onClick: () => void;
  disabled: boolean;
};

const RevibeOption = ({
  image,
  label,
  selected,
  onClick,
  disabled,
}: RevibeOptionProps) => {
  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: selected
          ? `3px solid ${
              disabled
                ? darken(theme.palette.emphasized.main, 0.3)
                : theme.palette.emphasized.main
            }`
          : "3px solid transparent",
        cursor: disabled ? "auto" : "pointer",
        boxSizing: "border-box",
        borderRadius: 1,
      })}
      onClick={handleClick}
    >
      <img src={image} alt={`Re-vibe ${label}`} height="auto" width={94} />
      <Typography
        fontSize={14}
        mt={1}
        sx={(theme) => ({
          color: disabled ? theme.palette.secondary.dark : "white",
        })}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default RevibeOption;
