import { getStripePayments } from "@stripe/firestore-stripe-payments";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

let firebaseConfig = {
  apiKey: "AIzaSyBY8oWoDSIavpKBuvL_wpESwQ6oDLMG1g0",
  authDomain: "beta.pixelvibe.com",
  projectId: "demos-375017",
  storageBucket: "demos-375017.appspot.com",
  messagingSenderId: "141781671289",
  appId: "1:141781671289:web:be358708959f185d31e3a8",
  measurementId: "G-74277Q3P30",
};

const stagingFirebaseConfig = {
  apiKey: "AIzaSyDjG_17_RfxYTb0XI98udG87VXeszRK1Dc",
  authDomain: "pixelvibe-staging.firebaseapp.com",
  projectId: "pixelvibe-staging",
  storageBucket: "pixelvibe-staging.appspot.com",
  messagingSenderId: "310472598480",
  appId: "1:310472598480:web:8c44771176195d46f10fd5",
  measurementId: "G-2D4SCLZZ6Y",
};

if (
  process.env.NODE_ENV === "development" ||
  process.env.REACT_APP_ENV === "staging"
) {
  firebaseConfig = stagingFirebaseConfig;
}

const firebaseApp = initializeApp(firebaseConfig);

const firebaseAuth = getAuth(firebaseApp);
const firebaseAnalytics = getAnalytics(firebaseApp);
const firebaseFirestore = getFirestore(firebaseApp);
const firebaseFunctions = getFunctions(firebaseApp);

const stripePayments = getStripePayments(firebaseApp, {
  productsCollection: "products",
  customersCollection: "customers",
});

if (process.env.NODE_ENV === "development") {
  connectAuthEmulator(firebaseAuth, "http://localhost:9099");
  connectFirestoreEmulator(firebaseFirestore, "localhost", 8080);
  connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
}

export {
  firebaseAuth,
  firebaseAnalytics,
  firebaseFirestore,
  firebaseFunctions,
  stripePayments,
};
