import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PropsWithChildren } from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./contexts/AuthContext";
import { ErrorContextProvider } from "./contexts/ErrorContext";

// Adding Typescript support for custom colors to MUI components

declare module "@mui/material/styles" {
  interface Palette {
    emphasized: Palette["primary"];
    emphasizedBg: Palette["primary"];
    pink1: Palette["primary"];
    pink2: Palette["primary"];
  }

  interface PaletteOptions {
    emphasized: PaletteOptions["primary"];
    emphasizedBg: PaletteOptions["primary"];
    pink1: PaletteOptions["primary"];
    pink2: PaletteOptions["primary"];
  }
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    emphasized: true;
  }
}
declare module "@mui/material/LinearProgress" {
  interface LinearProgressPropsColorOverrides {
    pink1: true;
  }
}

declare module "@mui/material/Slider" {
  interface SliderPropsColorOverrides {
    pink1: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    emphasized: true;
    emphasizedBg: true;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
  },
  palette: {
    primary: {
      main: "#1A1836",
    },
    secondary: {
      main: "#766cbf",
      dark: "#423F78",
    },
    emphasized: {
      main: "#E933BE",
      contrastText: "#FFF",
    },
    emphasizedBg: {
      main: "#1A1836",
    },
    pink1: {
      light: "#BD70E1",
      main: "#AD4DDA",
      dark: "#793598",
    },
    pink2: {
      main: "#FF8ECB",
    },
    background: {
      paper: "#2E2C54",
      default: "#121126",
    },
  },
});

const queryClient = new QueryClient();

const AppProviders = ({ children }: PropsWithChildren) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <BrowserRouter>
            <ErrorContextProvider>{children}</ErrorContextProvider>
          </BrowserRouter>
        </AuthContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default AppProviders;
