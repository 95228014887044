import { Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { ImageThumbnail } from "..";
import useGamingAssetsApi, {
  API_IMAGE_TYPE,
  PixelateImageOptions,
} from "../../../apis/useGamingAssetsApi";
import { DEFAULT_ERROR, ErrorContext } from "../../../contexts/ErrorContext";
import Expander from "../../common/Expander";
import StyledButton from "../../common/StyledButton";
import SquareSizeSlider from "../LeftPanel/SquareSizeSlider";
import LabeledSwitch from "./LabeledSwitch";

type PixelateExpanderProps = {
  selectedImage: ImageThumbnail | null;
  expanded: boolean;
  onExpand: (isExpanded: boolean) => void;
  onProcessingStart: () => void;
  onProcessingEnd: (result?: ImageThumbnail[]) => void;
  disabled?: boolean;
};
const PixelateExpander = ({
  selectedImage,
  expanded,
  onExpand,
  onProcessingStart,
  onProcessingEnd,
  disabled: propsDisabled,
}: PixelateExpanderProps) => {
  const { openErrorDialog } = React.useContext(ErrorContext);
  const api = useGamingAssetsApi();
  const [pixelSize, setPixelSize] = React.useState(16);
  const [addBorders, setAddBorders] = React.useState(false);

  const handleChangePixelSize = (value: number) => setPixelSize(value);
  const handleChangeAddBorders = (value: boolean) => setAddBorders(value);

  const { mutate, isLoading } = useMutation({
    mutationFn: (vars: PixelateImageOptions) =>
      api.pixelateImage(vars, onProcessingStart),
    onSuccess: (data, vars) => {
      const src = URL.createObjectURL(data.data);
      const img = new Image();
      img.src = src;
      img.onload = async () => {
        onProcessingEnd([
          {
            name: vars.imageThumbnail.name,
            ext: API_IMAGE_TYPE,
            blob: data.data,
            img,
            uuid: uuidv4(),
          },
        ]);
      };
    },
    onError: () => {
      onProcessingEnd();
      openErrorDialog(DEFAULT_ERROR);
    },
  });

  const handleClickPixelate = async () => {
    if (selectedImage) {
      mutate({
        imageThumbnail: selectedImage,
        pixelationValue: pixelSize,
        height: selectedImage.img.height / pixelSize,
        width: selectedImage.img.width / pixelSize,
        addBorders,
      });
    }
  };

  const disabled = propsDisabled || !selectedImage || isLoading;

  return (
    <Expander text="Pixelate" expanded={expanded} onChange={onExpand}>
      <Stack spacing={2}>
        <SquareSizeSlider
          label="Pixel Size"
          disabled={disabled}
          value={pixelSize}
          min={16}
          max={64}
          stepSize={16}
          onChange={handleChangePixelSize}
        />
        <LabeledSwitch
          disabled={disabled}
          value={addBorders}
          label="Outline"
          onChange={handleChangeAddBorders}
        />
        <StyledButton
          disabled={disabled}
          onClick={handleClickPixelate}
          fullWidth
        >
          Pixelate
        </StyledButton>
      </Stack>
    </Expander>
  );
};

export default PixelateExpander;
