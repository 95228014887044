import { Box, Button, Grid, Typography } from "@mui/material";
import { ImageThumbnail } from "..";
import DropzoneImage from "./DropzoneImage";

type ImagesViewerProps = {
  isLoading: boolean;
  images: ImageThumbnail[];
  selectedImage: ImageThumbnail | null;
  onChangeSelected: (selected: ImageThumbnail | null) => void;
  onReset: () => void;
};

const ImagesViewer = ({
  isLoading,
  images,
  selectedImage,
  onChangeSelected,
  onReset,
}: ImagesViewerProps) => {
  const handleClearImages = () => {
    onChangeSelected(null);
    onReset();
  };

  const handleClickImage = (image: ImageThumbnail) => {
    if (!isLoading) {
      if (image.uuid === selectedImage?.uuid) {
        onChangeSelected(null);
      } else {
        onChangeSelected(image);
      }
    }
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "660px",
        width: "660px",
        backgroundColor: theme.palette.primary.main,
        borderRadius: 4,
        flexGrow: 1,
      })}
    >
      <Box
        sx={{
          height: "15%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: 4,
        }}
      >
        <Typography
          color="secondary.light"
          fontSize="14px"
          fontWeight="fontWeightMedium"
        >
          Select images that you want to modify
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Button color="secondary" onClick={handleClearImages}>
            Clear
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "85%",
          p: 2,
        }}
      >
        <Grid container spacing={2} sx={{ height: "100%" }}>
          {images.map((image, index) => (
            <Grid
              item
              key={image.uuid}
              xs={images.length === 4 ? 6 : 12 / images.length}
            >
              <DropzoneImage
                isLoading={isLoading && selectedImage?.uuid === image.uuid}
                image={image}
                selected={selectedImage?.uuid === image.uuid}
                onClick={() => handleClickImage(image)}
                justifyContent={
                  images.length === 4
                    ? index % 2 === 0
                      ? "flex-end"
                      : "flex-start"
                    : "center"
                }
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ImagesViewer;
