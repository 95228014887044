import { Box, Button, Typography } from "@mui/material";
import { logEvent } from "firebase/analytics";
import {
  GoogleAuthProvider,
  User,
  signOut as firebaseSignout,
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth";
import React from "react";
import { ReactComponent as IconLogo } from "../assets/icon-logo.svg";
import StyledDialog from "../components/common/StyledDialog";
import { firebaseAnalytics, firebaseAuth } from "../firebase";

type AuthContextType = {
  user: User | null;
  signInWithGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
  openSessionDialog: () => Promise<User | null>;
  closeSessionDialog: () => void;
};

const googleOAuthProvider = new GoogleAuthProvider();

const AuthContext = React.createContext<AuthContextType>({
  user: null,
  signInWithGoogle: async () => {},
  signOut: async () => {},
  openSessionDialog: async () => null,
  closeSessionDialog: () => {},
});

const AuthContextProvider = ({ children }: React.PropsWithChildren) => {
  const [user, setUser] = React.useState<User | null>(firebaseAuth.currentUser);
  const [sessionDialogOpen, setSessionDialogOpen] = React.useState(false);
  // Tracking the initial load since the firebase user is loaded asynchronously and the
  // app will immediately redirect to the home page if no user is detected.
  const [loaded, setLoaded] = React.useState(false);
  // Storing a function as state is tricky. React setState functions have a callback
  // version which means a function passed in will immediately be called. Thus we need
  // to pass in a function which returns the actual function that we want.
  const [signInWithGoogle, setSignInWithGoogle] = React.useState<
    () => Promise<void>
  >(() => () => Promise.resolve());

  React.useEffect(() => {
    onAuthStateChanged(firebaseAuth, async (authUser) => {
      setUser(authUser);

      if (process.env.REACT_APP_INTERCOM_ENABLED === "true") {
        let intercomFn = "";
        let intercomPayload: any = {
          // @ts-expect-error
          app_id: APP_ID,
        };
        if (authUser === null) {
          intercomFn = "shutdown";
        } else {
          intercomFn = "boot";
        }

        if (authUser) {
          intercomPayload["email"] = authUser.email;
        }
        // https://developers.intercom.com/installing-intercom/docs/intercom-javascript
        // @ts-expect-error
        window.Intercom(intercomFn, intercomPayload);
      }

      setLoaded(true);
    });
  }, []);

  const openSessionDialog = () => {
    setSessionDialogOpen(true);

    return new Promise<User | null>((resolve, reject) => {
      const signInWithGoogle = () => async () => {
        try {
          const result = await signInWithPopup(getAuth(), googleOAuthProvider);

          logEvent(firebaseAnalytics, "login", { method: "google" });

          setUser(result.user);
          resolve(result.user);
        } catch (err) {
          console.error(err);
          resolve(null);
        }
      };

      // Again, a function that returns the function we want, see note above
      setSignInWithGoogle(signInWithGoogle);
    });
  };
  const closeDialog = () => setSessionDialogOpen(false);

  async function signOut() {
    await firebaseSignout(getAuth());
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        signInWithGoogle,
        signOut,
        openSessionDialog,
        closeSessionDialog: closeDialog,
      }}
    >
      {/* Only load in the rest of the app after we're sure if the user is actually logged in or not */}
      {loaded && children}
      <StyledDialog open={sessionDialogOpen} onClose={closeDialog}>
        <Box
          sx={{
            p: 4,
            alignSelf: "center",
            svg: { width: "70px", height: "70px" },
          }}
        >
          <IconLogo />
        </Box>
        <Box sx={{ width: "100%", p: 2 }}>
          <Button
            fullWidth
            onClick={user ? signOut : signInWithGoogle}
            sx={{ color: "white" }}
            color="emphasized"
            variant="outlined"
          >
            {user ? "Sign out" : "Sign in with Google"}
          </Button>
        </Box>
        <Box
          sx={{
            alignSelf: "center",
            p: 2,
            display: "flex",
          }}
        >
          <Typography fontSize="14px" color="secondary.main" sx={{ mr: 1 }}>
            By joining, you agree to our
          </Typography>
          <Typography fontSize="14px">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://rosebudstartup.notion.site/Terms-of-Service-cd08d6a076a041868696a8097931fdc4"
            >
              Terms of Service
            </a>
          </Typography>
        </Box>
      </StyledDialog>
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
