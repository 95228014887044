import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledButton = styled(
  ({ children, ...otherButtonProps }: ButtonProps) => {
    return (
      <Button color="emphasized" variant="contained" {...otherButtonProps}>
        {children}
      </Button>
    );
  }
)(({ theme }) => ({
  border: `1px solid ${theme.palette.pink2.main}`,
  "&.Mui-disabled": {
    color: theme.palette.secondary.dark,
    borderColor: theme.palette.secondary.dark,
  },
}));

export default StyledButton;
