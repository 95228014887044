import { Box } from "@mui/material";
import React from "react";
import LeftPanel from "./LeftPanel";
import Dropzone from "./MiddlePanel/Dropzone";
import ImagesViewer from "./MiddlePanel/ImagesViewer";
import RightPanel from "./RightPanel";

export type ImageThumbnail = {
  name: string;
  ext: string;
  blob: Blob;
  img: HTMLImageElement;
  uuid: string;
};

const AssetDesigner = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [images, setImages] = React.useState<ImageThumbnail[]>([]);
  const [selectedImage, setSelectedImage] =
    React.useState<ImageThumbnail | null>(null);

  const handleReset = React.useCallback(() => {
    // setIsLoading(false);
    setImages([]);
  }, []);

  const handleGenerateStart = React.useCallback(() => {
    // setImages([]);
    setSelectedImage(null);
    setIsLoading(true);
  }, []);

  const handleProcessingStart = React.useCallback(() => {
    setIsLoading(true);
  }, []);

  const handleProcessingEnd = React.useCallback((images?: ImageThumbnail[]) => {
    setIsLoading(false);
    if (images) {
      setImages(images);
      if (images.length === 1) {
        setSelectedImage(images[0]);
      }
    }
  }, []);

  const handleChangeSelected = React.useCallback(
    (image: ImageThumbnail | null) => {
      setSelectedImage(image);
    },
    []
  );

  return (
    <Box sx={{ display: "flex", py: 6, justifyContent: "center" }}>
      <Box pl={5}>
        <LeftPanel
          isLoading={isLoading}
          onGenerateStart={handleGenerateStart}
          onGenerateEnd={handleProcessingEnd}
        />
      </Box>
      {images.length > 0 && (
        <>
          <Box
            sx={{
              mx: 4,
              display: "flex",
              justifyContent: "center",
              flexGrow: 1,
            }}
          >
            <Dropzone
              isLoading={isLoading}
              onUploadStart={handleProcessingStart}
              onUploadEnd={handleProcessingEnd}
              hidden={images.length > 0}
            />
            {images.length > 0 && (
              <ImagesViewer
                isLoading={isLoading}
                images={images}
                selectedImage={selectedImage}
                onChangeSelected={handleChangeSelected}
                onReset={handleReset}
              />
            )}
          </Box>
          <Box pr={5}>
            <RightPanel
              isLoading={isLoading}
              selectedImage={selectedImage}
              onProcessingStart={handleProcessingStart}
              onProcessingEnd={handleProcessingEnd}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default AssetDesigner;
