import { alpha, Box, Dialog, DialogProps, IconButton } from "@mui/material";
import { ReactComponent as IconCircleX } from "../../assets/icon-circle-x.svg";

type StyledDialogProps = DialogProps & {
  onClose: () => void;
};

const StyledDialog = ({
  children,
  onClose,
  ...DialogProps
}: StyledDialogProps) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      sx={(theme) => ({
        "& .MuiPaper-root": {
          boxShadow: `0 0 10px ${alpha(theme.palette.pink1.main, 0.5)}`,
          border: `1px solid ${theme.palette.pink1.main}`,
          backgroundColor: theme.palette.primary.main,
          p: 2,
        },
      })}
      {...DialogProps}
    >
      <IconButton sx={{ alignSelf: "end", p: 0 }} onClick={handleClose}>
        <IconCircleX />
      </IconButton>
      <Box p={4} pt={0} display="flex" flexDirection="column">
        {children}
      </Box>
    </Dialog>
  );
};

export default StyledDialog;
