export type PotionModelSpell = {
  caption: string;
  icon: string;
};

export type PotionModel = {
  name: string;
  display_name: string;
  assets_location: string;
  prompts: PotionModelSpell[];
};

const modelData = {
  models: [
    {
      name: "Ancient Ruins",
      display_name: "Ancient Ruins",
      assets_location: "models/ancient_ruins",
      prompts: [
        {
          caption: "Abandoned Pyramid",
          icon: "abandoned_pyramid.jpg",
        },
        {
          caption: "Ancient Destroyed Portal",
          icon: "ancient_destroyed_portal.jpg",
        },
        {
          caption: "Crumbled Fortress",
          icon: "crumbled_fortress.jpg",
        },
        {
          caption: "Decayed Amphitheatre",
          icon: "decayed_amphitheatre.jpg",
        },
        {
          caption: "Destroyed Church",
          icon: "destroyed_church.jpg",
        },
        {
          caption: "Temple Overgrown with Vines",
          icon: "temple_overgrown_with_vines.jpg",
        },
      ],
    },
    {
      name: "pixel character full body sprites",
      display_name: "Pixel Character Full Body Sprites",
      assets_location: "models/pixel_character_full_body_sprites",
      prompts: [
        {
          caption: "Angel",
          icon: "angel.png",
        },
        {
          caption: "Bard",
          icon: "bard.png",
        },
        {
          caption: "Demon Slayer",
          icon: "demon_slayer.png",
        },
        {
          caption: "Demon",
          icon: "demon.png",
        },
        {
          caption: "Knight",
          icon: "knight.png",
        },
        {
          caption: "Mermaid",
          icon: "mermaid.png",
        },
      ],
    },
    {
      name: "fantasy aristocracy portraits",
      display_name: "Fantasy Aristocracy Portraits",
      assets_location: "models/fantasy_aristocracy_portraits",
      prompts: [
        {
          caption: "Half-orc Woman",
          icon: "half-orc_woman.png",
        },
        {
          caption: "Mermaid Princess",
          icon: "mermaid_princess.png",
        },
        {
          caption: "Satyr Woman",
          icon: "satyr_woman.png",
        },
        {
          caption: "Viking",
          icon: "viking.png",
        },
        {
          caption: "Werewolf",
          icon: "werewolf.png",
        },
        {
          caption: "Zombie Woman",
          icon: "zombie_woman.png",
        },
      ],
    },
    {
      name: "pixel pixie portraits",
      display_name: "Pixel Pixie Portraits",
      assets_location: "models/pixel_pixie_portraits",
      prompts: [
        {
          caption: "Dragonborn",
          icon: "dragonborn.png",
        },
        {
          caption: "Dwarf",
          icon: "dwarf.png",
        },
        {
          caption: "Elf",
          icon: "elf.png",
        },
        {
          caption: "Fairy",
          icon: "fairy.png",
        },
        {
          caption: "Leprechaun",
          icon: "leprechaun.png",
        },
        {
          caption: "Orc Woman",
          icon: "orc_woman.png",
        },
      ],
    },
    {
      name: "realistic skybox",
      display_name: "Realistic Sky",
      assets_location: "models/realistic_skybox",
      prompts: [
        {
          caption: "Autumnal Sky",
          icon: "autumnal_sky.png",
        },
        {
          caption: "Cumulus Clouds Sky",
          icon: "cumulus_clouds_sky.png",
        },
        {
          caption: "Ghibli Studio Anime Sky",
          icon: "ghibli_studio_anime_sky.png",
        },
        {
          caption: "No Sun Sky",
          icon: "no_sun_sky.png",
        },
        {
          caption: "Sky Glow",
          icon: "sky_glow.png",
        },
        {
          caption: "Smog-Filled Sky",
          icon: "smog-filled_sky.png",
        },
      ],
    },
    {
      name: "isometric tiles",
      display_name: "Isometric Tiles",
      assets_location: "models/isometric_tiles",
      prompts: [
        {
          caption: "Green Stone Wall",
          icon: "green_stone_wall.png",
        },
        {
          caption: "Lava",
          icon: "lava.png",
        },
        {
          caption: "Leaves",
          icon: "leaves.png",
        },
        {
          caption: "Marble",
          icon: "marble.png",
        },
        {
          caption: "Sci-fi Lava",
          icon: "sci-fi_lava.png",
        },
        {
          caption: "Snow",
          icon: "snow.png",
        },
      ],
    },
    {
      name: "cartoony items",
      display_name: "Cartoony Items",
      assets_location: "models/cartoony_items",
      prompts: [
        {
          caption: "Bunny",
          icon: "bunny.png",
        },
        {
          caption: "Candle",
          icon: "candle.png",
        },
        {
          caption: "Flower Pot",
          icon: "flower_pot.png",
        },
        {
          caption: "Instax Camera",
          icon: "instax_camera.png",
        },
        {
          caption: "Ring",
          icon: "ring.png",
        },
        {
          caption: "Young Dragon",
          icon: "young_dragon.png",
        },
      ],
    },
    {
      name: "dresses",
      display_name: "Dresses",
      assets_location: "models/dresses",
      prompts: [
        {
          caption: "Dark Purple Velvet Oblique Mini Dress",
          icon: "dark_purple_velvet_oblique_mini_dress.png",
        },
        {
          caption: "Burgundy Silk Charmeuse Off-the-Shoulder",
          icon: "burgundy_silk_charmeuse_off-the-shoulder.png",
        },
        {
          caption: "Burgundy Silk Charmeuse Off-the-Shoulder Long Dress",
          icon: "burgundy_silk_charmeuse_off-the-shoulder_long_dress.png",
        },
        {
          caption: "Gold and Black Brocade Mini Dress",
          icon: "gold_and_black_brocade_mini_dress.png",
        },
        {
          caption: "Pale Pink Silk Taffeta Belted Maxi Dress",
          icon: "pale_pink_silk_taffeta_belted_maxi_dress.png",
        },
        {
          caption: "White Cotton Poplin Collared Long Dress",
          icon: "white_cotton_poplin_collared_long_dress.png",
        },
      ],
    },
    {
      name: "pixel icons",
      display_name: "Pixel Icons",
      assets_location: "models/pixel_icons",
      prompts: [
        {
          caption: "Apple",
          icon: "apple.png",
        },
        {
          caption: "Flower",
          icon: "flower.png",
        },
        {
          caption: "Health Potion",
          icon: "health_potion.png",
        },
        {
          caption: "Perfume",
          icon: "perfume.png",
        },
        {
          caption: "Strawberry",
          icon: "strawberry.png",
        },
        {
          caption: "Yellow Button",
          icon: "yellow_button.png",
        },
      ],
    },
    {
      name: "prepared food",
      display_name: "Prepared Food",
      assets_location: "models/prepared_food",
      prompts: [
        {
          caption: "Cabbage and Bacon Soup",
          icon: "cabbage_and_bacon_soup.png",
        },
        {
          caption: "Cherry and Almond Tart",
          icon: "cherry_and_almond_tart.png",
        },
        {
          caption: "Cinnamon and Raisin Bread Pudding",
          icon: "cinnamon_and_raisin_bread_pudding.png",
        },
        {
          caption: "Garlic and Herb Roasted Potatoes",
          icon: "garlic_and_herb_roasted_potatoes.png",
        },
        {
          caption: "Garlic Roasted Chicken",
          icon: "garlic_roasted_chicken.png",
        },
        {
          caption: "Grilled Fish",
          icon: "grilled_fish.png",
        },
      ],
    },
    {
      name: "cooking ingredients",
      display_name: "Cooking Ingredients",
      assets_location: "models/cooking_ingredients",
      prompts: [
        {
          caption: "Almonds",
          icon: "almonds.png",
        },
        {
          caption: "Basil",
          icon: "basil.png",
        },
        {
          caption: "Beet",
          icon: "beet.png",
        },
        {
          caption: "Cabbage",
          icon: "cabbage.png",
        },
        {
          caption: "Eggs",
          icon: "eggs.png",
        },
        {
          caption: "Sugar",
          icon: "sugar.png",
        },
      ],
    },
    {
      name: "exoplanets environments",
      display_name: "Exoplanets Environments",
      assets_location: "models/exoplanets_environments",
      prompts: [
        {
          caption: "Burning Dunes On An Alien Planet",
          icon: "burning_dunes_on_an_alien_planet.png",
        },
        {
          caption: "Icy Exoplanet",
          icon: "icy_exoplanet.png",
        },
        {
          caption: "Rustic Countryside",
          icon: "rustic_countryside.png",
        },
        {
          caption: "Subterranean Cave System",
          icon: "subterranean_cave_system.png",
        },
        {
          caption: "Toxic Swamp",
          icon: "toxic_swamp.png",
        },
        {
          caption: "Volcanic Island",
          icon: "volcanic_island.png",
        },
      ],
    },
    {
      name: "epic space environments",
      display_name: "Epic Space Environments",
      assets_location: "models/epic_space_environments",
      prompts: [
        {
          caption: "At The Planet's Orbit",
          icon: "at_the_planets_orbit.png",
        },
        {
          caption: "Cosmic Explosion",
          icon: "cosmic_explosion.png",
        },
        {
          caption: "Meteor Shower",
          icon: "meteor_shower.png",
        },
        {
          caption: "Planets In Space",
          icon: "planets_in_space.png",
        },
        {
          caption: "Space Vortex",
          icon: "space_vortex.png",
        },
        {
          caption: "Sugar",
          icon: "sugar.png",
        },
      ],
    },
    {
      name: "cozy isometric rooms",
      display_name: "Cozy Isometric Rooms",
      assets_location: "models/cozy_isometric_rooms",
      prompts: [
        {
          caption: "Art Studio With A Big Window",
          icon: "art_studio_with_a_big_window.png",
        },
        {
          caption: "Beach-themed Office",
          icon: "beach-themed_office.png",
        },
        {
          caption: "Modern Cozy Kitchen",
          icon: "modern_cozy_kitchen.png",
        },
        {
          caption: "Modern Gym",
          icon: "modern_gym.png",
        },
        {
          caption: "Modern Reading Room With A Fireplace",
          icon: "modern_reading_room_with_a_fireplace.png",
        },
        {
          caption: "Retro Diner With Booths",
          icon: "retro_diner_with_booths.png",
        },
      ],
    },
    {
      name: "sci-fi helmets",
      display_name: "Sci-Fi Helmets",
      assets_location: "models/sci-fi_helmets",
      prompts: [
        {
          caption: "Blue Synthwave Helmet",
          icon: "blue_synthwave_helmet.png",
        },
        {
          caption: "Green Synthwave Helmet",
          icon: "green_synthwave_helmet.png",
        },
        {
          caption: "Orange Synthwave Helmet",
          icon: "orange_synthwave_helmet.png",
        },
        {
          caption: "Pink Synthwave Helmet",
          icon: "pink_synthwave_helmet.png",
        },
        {
          caption: "Slate Synthwave Helmet",
          icon: "slate_synthwave_helmet.png",
        },
        {
          caption: "Yellow Synthwave Helmet",
          icon: "yellow_synthwave_helmet.png",
        },
      ],
    },
  ],
} as { models: PotionModel[] };

export default modelData;
